<template>
  <div class="home">
    <CustomerApplicationViewer />
    <DeleteCustomerApplication />
    <CustomerApplicationsTable />
  </div>
</template>

<script>
// @ is an alias to /src
import CustomerApplicationsTable from "@/components/CustomerApplications/CustomerApplicationsTable.vue";
import DeleteCustomerApplication from "@/components/CustomerApplications/DeleteCustomerApplication.vue";
import CustomerApplicationViewer from "@/components/CustomerApplications/CustomerApplicationViewer.vue";

export default {
  name: "CustomerApplicationsView",
  components: {
    CustomerApplicationsTable,
    DeleteCustomerApplication,
    CustomerApplicationViewer,
  },
};
</script>
<style lang="scss">
.home {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
