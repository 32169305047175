<template>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn :color="color" text v-bind="attrs" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { eventBus } from "../../main";

export default {
  name: "SnackBarService",
  data: () => ({
    snackbar: false,
    text: null,
    color: "blue",
    timeout: 4000,
  }),
  created() {
    eventBus.$on("showSnackBar", (text, color) => {
      this.text = text;
      this.color = color;
      this.snackbar = true;
    });
  },
  methods: {},
};
</script>
