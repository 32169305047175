<template>
  <v-dialog v-model="dialog" width="1000px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Application - {{ item.company_name }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <iframe width="100%" height="600px" :src="customer_url"></iframe>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()"> Ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { eventBus } from "../../main";

export default {
  name: "CustomerApplicationViewer",
  data() {
    return {
      dialog: false,
      customer_url: "",
      item: {
        form_data: {
          company_name: null,
        },
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
  created() {
    eventBus.$on("showCustomerApplicationViewer", (item) => {
      console.log(item);
      this.dialog = true;
      this.customer_url = process.env.VUE_APP_CRM_URL + item.PartitionKey;
      this.item = item;
    });
  },
};
</script>
