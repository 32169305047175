<template>
  <v-app>
    <v-app-bar app color="#3F3F3F" dark>
      <v-toolbar-title>Customer Applications</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn href="#" target="_blank" text>
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <SnackBarService />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import SnackBarService from "./components/Shared/SnackBarService.vue";
export default {
  name: "App",
  data: () => ({
    //
  }),
  components: { SnackBarService },
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>
